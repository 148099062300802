<template>
  <full-model-view progress-dot :visible="visible" :title="title" :sub-title="subTitle" @cancel="handleCancel">
    <div slot="up-extra"></div>
    <div>
      <form-box
        @changeStatus="changeStatus"
        :isDisabled="isDisabled"
        :todoType="todoType"
        :officialId="officialId"
        :editType="editType"
        :randomCode="randomCode"
        @close="close"
        ref="box"
      />
    </div>
  </full-model-view>
</template>

<script>
import { Ellipsis, FullModelView } from '@/components'
import { mapState } from 'vuex'
import formBox from './fromBox.vue'
export default {
  name: 'StepsForm',
  components: {
    Ellipsis,
    FullModelView,
    formBox,
  },
  computed: {
    ...mapState({
      visible: (state) => state.bill.registration,
    }),
  },
  data() {
    return {
      advanced: false,
      orderStatusData: [],
      buyingScheduleData: [],
      productionScheduleData: [],
      title: '新增',
      subTitle: '收文登记',
      todoType: 'registration',
      // visible: false,
      current: 0,
      officialId: '',
      stepList: [
        { title: '填写', desc: '项目信息' },
        { title: '上传', desc: '项目附件' },
        { title: '进度', desc: '项目进度' },
        { title: '售后', desc: '项目售后' },
        { title: '反馈', desc: '项目备注' },
        { title: '开票', desc: '项目发票' },
        { title: '评价', desc: '客户评价' },
      ],
      project: null,
      submitLoading: false,
      projectChange: false,
      isDisabled: false, // 是否可编辑
      randomCode: null, // 乱码 保证每次进入请求最新数据
      editType: '', //修改类型
    }
  },
  methods: {
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    changeStatus({ officialId, todoType }) {
      this.officialId = officialId
      this.todoType = todoType
      this.title = '发起审批'
    },
    open({ type, id, current = 0 }) {
      let randomCode = Math.floor(Math.random() * 1024 * 1024 * 1024)
      this.editType = null
      this.isDisabled = false
      if (type === 'edit') {
        this.randomCode = randomCode
        this.title = '编辑'
        this.current = current
        this.project = null
        this.officialId = id
        this.todoType = 'registration'
      } else if (type === 'approval') {
        this.randomCode = randomCode
        this.officialId = id
        this.title = '发起审批'
        this.todoType = 'registration'
        // this.isDisabled = true
      } else if (type === 'add') {
        this.project = null
        this.current = current
        this.title = '新增'
        this.todoType = 'registration'
        this.officialId = ''
        this.$refs.box.resetDetail()
        // this.$refs.box.fileIds = [] // 清空当前上传的文件目录
      } else if (type === 'renewal') {
        // 续审
        this.randomCode = randomCode
        this.title = '修改续审'
        this.current = current
        this.project = null
        this.officialId = id
        this.todoType = 'registration'
        this.editType = 'renewal'
      } else if (type === 'retrial') {
        // 重审
        this.randomCode = randomCode
        this.title = '修改重审'
        this.current = current
        this.project = null
        this.officialId = id
        this.todoType = 'registration'
      }
      this.$store.commit('bill/setRegistration', true)
    },
    close() {
      console.log('close=============')
      this.$store.commit('bill/setRegistration', false)
      this.$emit('refresh')
    },
    handleCancel() {
      console.log('handleCancel====================')
      this.projectChange = false
      this.$store.commit('bill/setRegistration', false)
      this.advanced = false
      this.current = 0
      this.project = null
      this.$emit('ok')
    },
  },
}
</script>

<style scoped>
.add-steps-box {
  /*width: 1200px;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 160px;
  padding-bottom: 20px;
}
.add-content-box {
  margin: 0 auto;
  /*width: 1200px;*/
  padding: 0px 160px;
  box-sizing: border-box;
}
.add-content-block {
  margin: 0 auto;
}
.add-steps-actions {
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
