1<template>
  <div class="body">
    <div class="info-title bdr" v-if="$route.query.id">
      <a-button type="link" icon="left" @click="$router.go(-1)" />{{ info.name }}
    </div>
    <div>
      <a-row :gutter="10">
        <a-col :lg="16" :xl="16" :md="24">
          <!-- <div class="line">公文单</div> -->
          <mytable ref="mytable" :editType="editType" :todoType="todoType" :info.sync="info" :isDisabled="isDisabled" />
        </a-col>
        <a-col :lg="8" :xl="8" :md="24">
          <template v-if="editType == 'renewal'">
            <div class="process">
              <processMess
                :templateName="templateName"
                :approvalStatus="approvalStatus"
                :recipientStatus="recipientStatus"
                class="bdr"
                :from="from"
                :todoType="$route.query.todo"
                :documentNodeList="documentNodeList"
                :documentType="documentType"
                :nooseStatus="nooseStatus"
              />
            </div>
            <div class="submit-bt">
              <a-popconfirm placement="topRight" title="确定重新开始审批吗？" @confirm="submitRenewalData">
                <a-button type="primary" size="large"> 保存并发起续审 </a-button>
              </a-popconfirm>
            </div>
          </template>
          <template v-else>
            <myform
              :todoType="todoType"
              :officialId="officialId"
              @close="close"
              class="bdr"
              @setDetail="setDetail"
              :randomCode="randomCode"
              @changeStatus="changeStatus"
              :content.sync="content"
              :fileIds.sync="fileIds"
              ref="myForm"
            />
          </template>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { retrial } from '@/views/fileStream/documentManage/minxins'
import { corpId, userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
import moment from 'moment'
import editor from './editor'
import myform from './form.vue'
import mytable from './table.vue'
import upload from './upload'
import { documentTabDefaultByType } from '@/api/modular/fileStream/processingSignature'
export default {
  components: { mytable, myform, upload, editor, processMess },
  mixins: [retrial],
  props: {
    officialId: {
      type: String,
      default: '',
    },
    todoType: {
      type: String,
      default: 'registration',
    },
    randomCode: 0,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    editType: {
      type: '',
      default: '',
    },
  },
  data() {
    return {
      fileIds: [],
      content: '',
      info: {},
      moment,
    }
  },

  methods: {
    setDetail(data) {
      this.info = data
      const { content, documentFileList } = data
      this.content = content
      this.fileIds = documentFileList
    },
    // 清空页面缓存值
    resetDetail() {
      this.$refs.mytable && this.$refs.mytable.resetFormInfo()
      this.$refs.myForm && this.$refs.myForm.resetList()
      documentTabDefaultByType(1)
      .then(res => {
        if (res.code === 200) {
          this.info = {
            name: res.data,
            fileIds: [],
            attach: [],
            draftId: [
              {
                type: 'userName',
                corpId,
                userId
              }
            ]
          }
        }
      })
      this.content = ''
      this.fileIds = []
    },
    changeStatus(evt) {
      this.$emit('changeStatus', evt)
    },
    close() {
      this.$emit('close')
    },
    //提交续审数据
    submitRenewalData() {
      const values = this.getFormObj()
      const obj = {
        acceptTime: values?.lwsj ? moment(values?.lwsj).format('YYYY-MM-DD') : null,
        name: values?.name,
        urgencyDegree: Number(values?.jjcd),
        secrecyDegree: Number(values?.miji),
        deviseOpinion: values?.nbyj,
        // approvalWay: Number(values.spfs),
        receiverCompany: values?.receiverCompany,
        draftId: this.filter(values?.draftId),
        tellPhone: values?.tellPhone,
        summary: values?.summary,
        documentType: 1,
        documentNumber: values?.documentNumber,
        title: values?.wjbt,
        attach: values?.attach && values?.attach[0],
        fileIds: values?.fileIds,
        status: 2,
        templateId: values?.templateId,
        id: values?.id,
      }
      this.handleSubmit(obj)
    },
  },
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.font {
  font-size: 16px;
}
.body {
  max-width: 1280px;
  margin: auto;
}
.left-com {
  padding: 20px;
  background: #fff;
  // width: 792px;
}
// 修改重审css
.process {
  padding: 20px 25px;
  padding-top: 94px;
}
.submit-bt {
  text-align: right;
  margin: 40px 0 40px;
  button {
    // width: 100px;
    height: 36px;
    background: #3b9afa;
    border-radius: 4px;
    margin-right: 10px;
  }
}
// 修改重审css
.info-title {
  width: 1279px;
  height: 66px;
  background: #ffffff;

  font-size: 18px;
  color: #565656;
  margin-bottom: 20px;
  line-height: 66px;
  button {
    margin: 0 10px;
    color: #c1c1c1;
  }
}
.bdr {
  border-radius: 4px;
}
.line {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #3e3d3d;
}
.line:first-child {
  margin-top: 0;
}
.line::before {
  content: '';
  width: 4px;
  height: 20px;
  background: #3b9afa;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 10px;
}
</style>